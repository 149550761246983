import React from 'react';
import { Link } from 'react-router-dom';
import Translate from '@spordle/intl-elements';

import { useOffices } from '../../queries/offices';

import Loader from '../Loader';

const League = ({ office, onClick }) =>
  <div className="nav-selector-option">
    <Link to={`/leagues/${office.id}`} title={office.name} className="dropdown-item d-flex flex-column justify-content-start" onClick={onClick}>
      <div className="nav-selector-logo pt-3" style={{ backgroundImage: `url(${office.logo && office.logo.url})` }} />
      <h4 className="pb-3 ml-1 mr-1">{office.name}</h4>
    </Link>
  </div>

const Leagues = ({ offices, toggle }) => {
  if (!offices?.length) return <section><Translate id="components.leagueselector.no.leagues.found" /></section>
  return <section>
    {offices.map(office =>
      <League key={office.id} office={office} onClick={toggle} />
    )}
  </section>
}

export default ({ toggle }) => {
  const { data: offices, status } = useOffices({ type: 'League' });

  return <>
    <div className="w-100 pt-4 px-4 px-sm-5 d-flex justify-content-between">
      <h2><Translate id="common.lang.leagues" /></h2>
    </div>
    <div className="nav-selector-step">
      {status === 'loading' && <Loader />}
      {status === 'error' && <Translate id="components.leagueselector.unable.to.load" />}
      {status === 'success' && <Leagues offices={offices} toggle={toggle} />}
    </div>
  </>
}

import enCA from './messages/en-CA.js';
import frCA from './messages/fr-CA.js';

export const LOCALES = {
  ENGLISH: 'en-ca',
  FRENCH: 'fr-ca',
};

export const messages = {
  [LOCALES.ENGLISH]: {
    ...enCA,
  },
  [LOCALES.FRENCH]: {
    ...frCA,
  }
}

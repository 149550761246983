import React, { useEffect } from 'react';
import Translate from '@spordle/intl-elements';

import { translateApiProperty } from '@hisports/common';

import Loader from '../../Loader';
import { useLocale } from '../../../i18n';

export default ({ divisions, status, seasonId, onSelect }) => {
  const [ locale ] = useLocale();

  useEffect(() => {
    if (status !== 'success') return;
    if (divisions?.length !== 1) return;
    onSelect(divisions[0]?.id)
  }, [ status, divisions, onSelect ])

  if (status === 'idle') return null;
  if (status === 'loading') return <Loader />
  if (status === 'error') return <p><Translate id="components.teamselector.division.loading.fail" /></p>

  if (!divisions?.length) return <section className="w-100 d-flex mt-5 px-4 justify-content-start">
    <span className="font-weight-normal"><Translate id="components.teamselector.division.no.divisions" values={{ season: seasonId }} /></span>
  </section>

  return <section>
    <h3 className="w-100 mt-3 ml-sm-4"><Translate id="common.lang.division" /></h3>
    {divisions.map(division => {
      const name = translateApiProperty(division, 'name', locale)
      return <div key={division.id} className="nav-selector-option" onClick={() => onSelect(division.id)}>
        <a href="#" title={name} onClick={e => e.preventDefault()}>
          <h4 className="pt-0 pb-3 mx-1">{name}</h4>
        </a>
      </div>
    })}
  </section>
}

import { useQuery } from "react-query"

import { useConfig } from "../components/ConfigContext"
import { load, sortOffices } from "../lib"

const loadOffices = (filter = {}, apiUrl) => {
  const { officeId, ...where } = filter;
  const request = load(`${apiUrl}/offices`, {
    filter: {
      where: {
        effectiveOffices: officeId,
        ...where,
      },
      include: 'logo',
    }
  })
  const result = request.then(sortOffices)

  result.cancel = request.cancel;
  return result;
}

const loadOffice = (officeId, apiUrl) => {
  return load(`${apiUrl}/offices/${officeId}`, {
    filter: {
      include: 'logo'
    }
  })
}

export const useOffices = (filter, options) => {
  const { apiUrl } = useConfig();

  return useQuery(
    ['offices', filter],
    () => loadOffices(filter, apiUrl),
    options
  )
}

export const useOffice = (officeId, options) => {
  const { apiUrl } = useConfig();
  return useQuery(
    ['office', officeId],
    () => loadOffice(officeId, apiUrl),
    { enabled: !!officeId, ...options }
  )
}

import React, { useState } from 'react';
import { Navbar, NavbarToggler, Collapse, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';

import { usePages } from '../queries/pages';

import Header from './Header';
import NavLink from './NavLink';
import { useConfig } from './ConfigContext';
import TeamSelector from './TeamSelector';
import LeagueSelector from './LeagueSelector';
import TeamShortcut from './MyTeam/TeamShortcut';

const Section = ({ section }) => {
  if (!section.pages?.length) return null;

  const pages = orderBy(section.pages, ['order', 'name'], ['asc', 'asc']);
  return <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret className="menu-item">
      {section.name}
    </DropdownToggle>
    <DropdownMenu right>
      {pages.map(page =>
        <DropdownItem key={page.id} tag="div">
          <NavLink to={`/pages/${page.slug}`} label={page.title} />
        </DropdownItem>
      )}
    </DropdownMenu>
  </UncontrolledDropdown>
}

const PagesNav = () => {
  const { data: sections, status } = usePages();

  if (status !== 'success' || !sections?.length) return null;
  return sections.map(section => <Section section={section} key={section.id} />)
}

export default () => {
  const { enableCMS, iframed } = useConfig()
  const { formatMessage } = useIntl();
  if (iframed) return null;

  const { hideStats, hideLeagues, hideTournaments, hideTeamSelector, backgroundImage } = useConfig();
  const [ isOpen, setOpen ] = useState(false);
  const toggle = () => setOpen(isOpen => !isOpen);

  const container = !!backgroundImage ? 'container' : 'container-fluid';
  return <header className="d-flex flex-row justify-content-center align-items-center">
    <div className={`px-0 ${container} bg-white`}>
      <div className="row no-gutters">
        <Navbar expand="sm" className="col-12 navbar-light p-0">
          <Header />
          {!hideTeamSelector && <TeamShortcut />}
          <NavbarToggler className="mr-3" onClick={toggle}>
            <span className="navbar-toggler-icon" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <Nav navbar>
              <NavLink to="/games" label={formatMessage({ id: 'common.lang.calendar' })} />
              {!hideStats && <NavLink to="/stats" label={formatMessage({ id: 'common.lang.stats' })} />}
              <NavLink to="/standings" label={formatMessage({ id: 'common.lang.standings' })} />
              {!hideTournaments && <NavLink to="/schedules" label={formatMessage({ id: 'common.lang.tournaments' })} />}
              {!hideLeagues && <LeagueSelector />}
              {!hideTeamSelector && <TeamSelector />}
              {enableCMS && <PagesNav />}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  </header>
}

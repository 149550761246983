import React from 'react';
import { Button } from 'reactstrap';

import { getGenericLocale, useLocale, LOCALES } from '../i18n';

const LanguageMenu = () => {
  const state = useLocale();
  const [ locale, setLocale ] = state;

  const languages = Object.values(LOCALES)
  const currentLanguage = languages.indexOf(locale)
  const nextLanguage = languages[(currentLanguage + 1) % languages.length]

  return <Button color="light" outline size="sm" onClick={() => setLocale(nextLanguage)}>
    {getGenericLocale(nextLanguage).toUpperCase()}
  </Button>
}

export default LanguageMenu;

/* eslint-disable import/no-named-as-default-member */
import React, { Component, Suspense } from 'react';
import { withRouter, Switch, Route, Redirect, matchPath } from 'react-router';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
import qs from 'qs';
import { pick } from 'lodash';

import { ConfigContext } from '../components/ConfigContext';
import Loader from '../components/Loader';

const Home = React.lazy(() => import('./Home'))
const PagePage = React.lazy(() => import('./Page'))
const PostPage = React.lazy(() => import('./Post'))

const GameListPage = React.lazy(() => import('./GameList'));
const StatsPage = React.lazy(() => import('./Stats'));
const StandingsPage = React.lazy(() => import('./Standings'));
const ScheduleListPage = React.lazy(() => import('./ScheduleList'));
const Player = React.lazy(() => import('./Player'));

const TeamPage = React.lazy(() => import('./Team'));
const TeamStatsFrame = React.lazy(() => import('./frames/TeamStats'));

const SchedulePage = React.lazy(() => import('./Schedule'));
const ScheduleGamesFrame = React.lazy(() => import('./frames/ScheduleGamesList'));
const ScheduleStatsFrame = React.lazy(() => import('./frames/ScheduleStats'));
const ScheduleStandingsFrame = React.lazy(() => import('./frames/ScheduleStandings'));

const OfficePage = React.lazy(() => import('./Office'));

const BoxScorePage = React.lazy(() => import('./BoxScore'));
const BoxScoreModal = React.lazy(() => import('./modals/BoxScore'));

const Loading = () =>
  <div className="container"><Loader>Loading...</Loader></div>

class Routes extends Component {
  static contextType = ConfigContext;

  previousLocation = this.props.location;

  unlisten = null;

  componentDidMount() {
    const { gaSiteId } = this.context;

    this.setupAnalytics(gaSiteId);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { location } = this.props;

    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  setupAnalytics(gaSiteId) {
    const { location, history } = this.props;

    if (!gaSiteId) return;
    ReactGA.initialize(gaSiteId)
    ReactGA.pageview(location.pathname)

    this.unlisten = history.listen(location => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    })
  }

  render() {
    const { siteName, hideStats, hideTournaments, hideBoxScore, enableCMS } = this.context;

    const { match, location: currentLocation, history, children, staticContext, ...parentProps } = this.props;

    const isModal = !!(
      currentLocation.state?.modal &&
      this.previousLocation !== currentLocation
    );

    const matches = matchPath(currentLocation.pathname, { path: '/games/:gameId' });
    const gameId = !isModal || !matches.params ? null : matches.params.gameId;

    const location = isModal ? this.previousLocation : currentLocation;
    let search = pick(qs.parse(location.search.substring(1)), ['scheduleId', 'groupId', 'teamId', 'perPage']);
    search = Object.keys(search).reduce((keys, key) => {
      try {
        keys[key] = parseInt(search[key], 10);
      } catch (e) { /* noop */ }
      return keys;
    }, {})
    const routeProps = props => Object.assign({}, search, parentProps, props);

    const titleTemplate = siteName ? `%s - ${siteName}` : '%s'
    return <>
      <Helmet
        defaultTitle={siteName}
        titleTemplate={titleTemplate}
      />
      <Suspense fallback={<Loading />}>
        <Switch location={location}>
          {enableCMS
            ? <Route path="/" exact render={
              props => <Home {...routeProps(props)} />
            } />
            : <Route path="/" exact render={
              props => <GameListPage {...routeProps(props)} />
            } />
          }

          <Route path="/games" exact render={
            props => <GameListPage {...routeProps(props)} />
          } />
          <Redirect from="/schedule" exact to="/games" />

          {!hideBoxScore && !isModal && <Route path="/games/:gameId" exact render={
            props => <BoxScorePage {...routeProps(props)} />
          } />}

          {!hideStats && <Route path="/stats" render={
            props => <StatsPage {...routeProps(props)} />
          } />}

          {!hideStats && <Route path="/players/:participantId" render={
            props => <Player {...routeProps(props)} />
          } />}

          <Route path="/standings" exact render={
            props => <StandingsPage {...routeProps(props)} />
          } />

          <Route path="/teams/:teamId" exact render={
            props => <TeamPage {...routeProps(props)} />
          } />
          {!hideStats && <Route path="/teams/:teamId/stats" exact render={
            props => <TeamStatsFrame {...routeProps(props)} />
          } />}

          {!hideTournaments && <Route path="/schedules" exact render={
            props => <ScheduleListPage {...routeProps(props)} />
          } />}
          {!hideTournaments && <Redirect from="/competitions" exact to="/schedules" />}
          {!hideTournaments && <Redirect from="/tournaments" exact to="/schedules" />}

          <Route path="/schedules/:scheduleId" exact render={
            props => <SchedulePage {...routeProps(props)} />
          } />
          <Redirect from="/competitions/:scheduleId" to="/schedules/:scheduleId" />

          <Route path="/schedules/:scheduleId/games" render={
            props => <ScheduleGamesFrame {...routeProps(props)} />
          } />
          {!hideStats && <Route path="/schedules/:scheduleId/stats" render={
            props => <ScheduleStatsFrame {...routeProps(props)} />
          } />}
          <Route path="/schedules/:scheduleId/standings" render={
            props => <ScheduleStandingsFrame {...routeProps(props)} />
          } />

          <Route path="/leagues/:officeId" render={
            props => <OfficePage {...routeProps(props)} />
          } />

          {enableCMS && <Route path="/pages/:slug" exact render={
            props => <PagePage {...routeProps(props)} />
          } />}
          {enableCMS && <Route path="/news/:year/:month/:day/:slug" exact render={
            props => <PostPage {...routeProps(props)} />
          } />}
        </Switch>
      </Suspense>
      {!hideBoxScore && <Suspense fallback={null}>
        {isModal && gameId && <BoxScoreModal {...parentProps} history={history} gameId={gameId} />}
      </Suspense>}
    </>
  }
}

export default withRouter(Routes);

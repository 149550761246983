import React, { createContext, useContext } from 'react';

import { parseOffice, parseEnv } from '../lib';

const appName = 'Spordle Play'

const config = {
  appName,
  siteName: process.env.SITE_NAME || appName,
  siteCover: process.env.SITE_COVER || null,
  siteType: process.env.SITE_TYPE || null,
  apiUrl: process.env.LOOPBACK_API,
  reportUrl: process.env.REPORT_URL,
  seasonId: process.env.CURRENT_SEASON,
  officeId: parseOffice(process.env.OFFICE_ID),
  gaSiteId: process.env.GA_SITE_ID || null,
  hideStats: parseEnv(process.env.HIDE_STATS) === true,
  hideTournaments: parseEnv(process.env.HIDE_TOURNAMENTS) === true,
  hideLeagues: parseEnv(process.env.HIDE_LEAGUES) === true,
  hideTeamSelector: parseEnv(process.env.HIDE_TEAM_SELECTOR) === true,
  hideBoxScore: parseEnv(process.env.HIDE_BOX_SCORE) === true,
  backgroundImage: process.env.BACKGROUND_IMAGE || null,
  reverseTeams: parseEnv(process.env.REVERSE_TEAMS) === true,
  teamSkaterSorts: parseEnv(process.env.TEAM_SKATER_SORTS),
  teamSkaterOrders: parseEnv(process.env.TEAM_SKATER_ORDERS),
  teamGoalieSorts: parseEnv(process.env.TEAM_GOALIE_SORTS),
  teamGoalieOrders: parseEnv(process.env.TEAM_GOALIE_ORDERS),
  enableCMS: parseEnv(process.env.ENABLE_CMS) === true,
  defaultScheduleType: process.env.DEFAULT_SCHEDULE_TYPE || 'League',
  socialTwitter: process.env.SOCIAL_TWITTER || null,
  socialFacebook: process.env.SOCIAL_FACEBOOK || null,
  socialInstagram: process.env.SOCIAL_INSTAGRAM || null,
  socialYouTube: process.env.SOCIAL_YOUTUBE || null,
  iframed: window.location !== window.parent.location,
  sponsors: parseEnv(process.env.SPONSORS),
  hideKraft: parseEnv(process.env.HIDE_KRAFT) === true,
}

export const ConfigContext = createContext(config);

export const ConfigProvider = props =>
  <ConfigContext.Provider value={config} {...props} />

export const useConfig = () => useContext(ConfigContext)


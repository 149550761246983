import React from 'react';
import { useIntl } from 'react-intl';
import Translate from '@spordle/intl-elements';

import { OFFICE_TYPES } from '../../../lib';
import Loader from '../../Loader';

const Office = ({ office, onSelect }) =>
  <div className="nav-selector-option" onClick={() => onSelect(office)}>
    <a href="#" title={office.name} onClick={e => e.preventDefault()}>
      <div className="nav-selector-logo pt-3" style={{ backgroundImage: `url(${office.logo && office.logo.url})` }} />
      <h4 className="pb-3 mx-1">{office.name}</h4>
    </a>
  </div>

export default ({ offices, status, onSelect }) => {
  const { formatMessage } = useIntl();

  if (status === 'idle') return null;
  if (status === 'loading') return <Loader />
  if (status === 'error') return <p><Translate id="components.teamselector.associations" /></p>

  const types = OFFICE_TYPES.map(type => {
    return {
      type,
      offices: offices.filter(office => office.type === type)
    }
  })

  return <>{
    types.map(type => {
      if (!type.offices.length) return null;
      return <section key={type.type}>
        <h3 className="w-100 mt-3 ml-sm-4">{formatMessage({ id: `common.enums.officetypes.${type.type}` })}</h3>
        {type.offices.map(office =>
          <Office key={office.id} office={office} onSelect={onSelect} />
        )}
      </section>
    })
  }</>
}

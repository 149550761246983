import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { useOffice } from '../queries/offices';
import { useConfig } from './ConfigContext';

export default () => {
  const { officeId, siteName } = useConfig();
  const { data: office } = useOffice(officeId);

  if (!office || !office.logo) return null;
  return <>
    <Helmet link={[{ rel: 'shortcut icon', href: office.logo.url }]} />
    <Link to="/" className="navbar-brand pl-3 pl-sm-3">
      <img src={office.logo.url} title={siteName || null} alt={siteName || null} />
    </Link>
  </>
}

import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Translate from '@spordle/intl-elements';

import LeagueSelector from './LeagueSelector';

export default () => {
  const [ isOpen, setOpen ] = useState(false);

  const toggle = () => setOpen(open => !open);
  return <Dropdown className="menu-item nav-item" isOpen={isOpen} toggle={toggle} inNavbar>
    <DropdownToggle caret tag="a" nav>
      <Translate id="common.lang.leagues" />
    </DropdownToggle>
    <DropdownMenu className="nav-selector-menu dropdown-menu-right">
      {isOpen && <LeagueSelector toggle={toggle} />}
    </DropdownMenu>
  </Dropdown>
}

import { useQuery } from 'react-query';
import { orderBy } from 'lodash';

import { useConfig } from "../components/ConfigContext"
import { load } from "../lib"

const loadPages = (apiUrl) => {
  const request = load(`${apiUrl}/pages`, {
    filter: {
      include: 'section'
    }
  })

  const result = request.then(pages => {
    let sections = pages.reduce((sections, page) => {
      const { section, ...rest } = page;
      if (!sections[page.section.name]) {
        sections[page.section.name] = {
          ...section,
          pages: []
        }
      }
      sections[page.section.name].pages.push(rest)
      return sections;
    }, {})

    sections = Object.keys(sections)
      .map(title => sections[title])

    sections = orderBy(sections, ['order', 'name'], ['asc', 'asc'])

    return sections;
  })

  result.cancel = request.cancel;
  return result;
}

const loadPage = (slug, apiUrl) => {
  const request = load(`${apiUrl}/pages`, {
    filter: {
      where: { slug },
      limit: 1,
    }
  })
  const result = request.then(pages => pages[0])

  result.cancel = request.cancel;
  return result;
}

export const usePages = (options) => {
  const { apiUrl } = useConfig();

  return useQuery(
    ['pages'],
    () => loadPages(apiUrl),
    options
  )
}

export const usePage = (slug, options) => {
  const { apiUrl } = useConfig();

  return useQuery(
    [ 'page', slug ],
    () => loadPage(slug, apiUrl),
    { enabled: !!slug, ...options }
  )
}

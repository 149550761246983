import "core-js/stable";

import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query';
import { useIntl } from 'react-intl'

import './styles/index.scss';

import { ConfigProvider, useConfig } from './components/ConfigContext';
import Navigation from './components/Navigation';
import { MyTeamProvider } from "./components/MyTeam/MyTeamContext";
import Footer from './components/Footer';
import Routes from './routes';

import { I18nProvider } from "./i18n";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: (failureCount, error) => {
        const status = error?.error?.statusCode;
        if (status >= 400 && status < 500) return false;
        return failureCount < 3;
      }
    }
  }
});

const iframed = window.location !== window.parent.location;

if (iframed) {
  const observer = new ResizeObserver(() => {
    const height = document.body.scrollHeight;
    const message = { type: 'height', height };
    window.parent.postMessage(message, '*')
  });
  observer.observe(document.body)
}

const App = () => {
  useIntl();
  const { backgroundImage } = useConfig();
  const className = `hisports-root ${iframed ? 'iframed' : ''}`

  let style;
  if (backgroundImage) {
    style = { backgroundImage: `url(${backgroundImage})` }
  }
  return <div className={className} style={style}>
    <Navigation />
    <div className="wrap hisports-body" role="document">
      <div className="content">
        <main className="main">
          <Routes />
        </main>
      </div>
    </div>
    <Footer />
  </div>
}

ReactDOM.render(
  <I18nProvider>
    <ConfigProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <MyTeamProvider>
            <App />
          </MyTeamProvider>
        </Router>
      </QueryClientProvider>
    </ConfigProvider>
  </I18nProvider>
  , document.getElementById('root'));

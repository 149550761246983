import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useTeam } from '../../queries/teams';
import { useConfig } from '../ConfigContext';

import { useMyTeam } from './MyTeamContext';

const TeamShortcut = () => {
  const { seasonId } = useConfig();
  const [ teamId ] = useMyTeam();
  const { data: team, status } = useTeam(teamId);
  const { formatMessage } = useIntl();

  if (status !== 'success' || !team) return null;
  if (team.seasonId !== seasonId) return null;
  return <Link to={`/teams/${team.id}`} className="navbar-brand" title={formatMessage({ id: 'components.myteam.go.to.team.page' })}>
    <img className="team-selector-team-nav-logo pl-md-3 w-auto" src={team.logo && team.logo.url} />
  </Link>
}

export default TeamShortcut;

import React from 'react';
import { Link } from 'react-router-dom';
import Translate from '@spordle/intl-elements';

import { translateApiProperty } from '@hisports/common';

import Loader from '../../Loader';
import { useLocale } from '../../../i18n';

const Team = ({ team, onSelect }) =>
  <div className="nav-selector-option" onClick={() => onSelect(team)}>
    <Link to={`/teams/${team.id}`} title={team.name}>
      <div className="nav-selector-logo mt-3" style={{ backgroundImage: `url(${team.logo && team.logo.url})` }} />
      <h4 className="pb-3 mx-1">{team.name}</h4>
    </Link>
  </div>

export default ({ teams, categories, divisionId, status, onSelect }) => {
  const [ locale ] = useLocale();

  if (status === 'idle') return null;
  if (status === 'loading') return <Loader />
  if (status === 'error') return <p><Translate id="components.teamselector.team.loading.fail" /></p>

  if (!teams?.length) return <section className="w-100 d-flex mt-5 px-4 justify-content-start">
    <span className="font-weight-normal"><Translate id="components.teamselector.team.no.teams" /></span>
  </section>

  const sections = categories
    .filter(category => category.divisionId === divisionId)
    .map(category => ({
      id: category.id,
      category,
      teams: teams.filter(team => team.categoryId === category.id)
    }))

  return <>{sections.map(({ id, category, teams }) => {
    if (!teams?.length) return null;
    return <section key={id}>
      <h3 className="w-100 mt-3 ml-sm-4">{translateApiProperty(category, 'name', locale)}</h3>
      {teams.map(team =>
        <Team key={team.id} team={team} onSelect={onSelect} />
      )}
    </section>
  })}</>
}

import { useQuery } from "react-query"

import { useConfig } from "../components/ConfigContext"
import { load } from "../lib"

const loadTeams = (filter, apiUrl) => {
  const { officeId, categoryId, seasonId } = filter;
  return load(`${apiUrl}/teams`, {
    filter: {
      where: {
        effectiveOffices: officeId,
        categoryId,
        seasonId,
      },
      include: ['category', 'logo'],
    }
  })
}

const loadTeam = (teamId, apiUrl) => {
  return load(`${apiUrl}/teams/${teamId}`, {
    filter: {
      include: ['category', 'logo'],
    }
  })
}

export const useTeams = (filter, options) => {
  const { apiUrl } = useConfig();
  return useQuery(
    ['teams', filter],
    () => loadTeams(filter, apiUrl),
    options
  )
}

export const useTeam = (teamId, options) => {
  const { apiUrl } = useConfig();
  return useQuery(
    ['team', teamId],
    () => loadTeam(teamId, apiUrl),
    { enabled: !!teamId, ...options }
  )
}

/* eslint-disable key-spacing */
export default {
  // COMPONENTS
  "components.boxscore.loading":                        "Chargement du pointage...",
  "components.boxscore.loading.fail":                   "Impossible de charger cette match",
  "components.boxscore.game.title":                     "Partie {number}",
  "components.boxscore.table.header.final":             "Finale",
  "components.boxscore.table.header.1st":               "1RE",
  "components.boxscore.table.header.2nd":               "2IEME",
  "components.boxscore.table.header.3rd":               "3IEME",
  "components.boxscore.table.header.overtime":          "PROL",
  "components.boxscore.table.header.shootout":          "FU",
  "components.boxscore.table.header.total":             "T",
  "components.boxscore.table.header.final.title":       "Final",
  "components.boxscore.table.header.1st.title":         "Première Période",
  "components.boxscore.table.header.2nd.title":         "Deuxième Période",
  "components.boxscore.table.header.3rd.title":         "Troisième Période",
  "components.boxscore.table.header.overtime.title":    "Prolongation",
  "components.boxscore.table.header.shootout.title":    "Fusillade",
  "components.boxscore.table.header.total.title":       "Totale",
  "components.boxscore.view.full.game.report":          "Voir la feuille de match",
  "components.boxscore.scoring.no.goals":               "Il n'y a pas de buts pour ce match",
  "components.boxscore.shots.table.header":             "Tirs au filet",
  "components.eventslist.load.fail":                    "Impossible de charger les événements à venir pour le moment",
  "components.eventslist.no.events":                    "Il n'y a pas d'événements à venir pour le moment",
  "components.filters.category":                        "Catégorie",
  "components.filters.division":                        "Division",
  "components.filters.gender":                          "Identité de genre",
  "components.filters.group":                           "Groupes",
  "components.filters.office":                          "Ligue / Association",
  "components.filters.schedule":                        "Horaire",
  "components.filters.schedule.type":                   "Type de Saison",
  "components.filters.season":                          "Saison",
  "components.gamelist.loading":                        "Chargement de l'horaire...",
  "components.gamelist.load.fail":                      "Impossible de charger les matchs",
  "components.gamelist.no.games.today":                 "Il n'y a pas de matchs cédulés aujourd'hui",
  "components.gamelist.no.games.that.day":              "Il n'y a pas de matchs cédulés le {day}",
  "components.gamelist.no.games.that.day.past":         "Il n'y avait pas de matchs cédulés le {day}",
  "components.gamelist.no.upcoming.games":              "Il n'y a pas de matchs à venir",
  "components.gamelist.tap.to.read.more":               "Appuyez pour en savoir plus",
  "components.leagueselector.no.leagues.found":         "Aucune ligue trouvée",
  "components.leagueselector.unable.to.load":           "Impossible de charger les ligues",
  "components.participantstats.select.schedule":        "Sélectionnez un horaire pour afficher les statistiques",
  "components.participantstats.loading":                "Chargement des statistiques...",
  "components.participantstats.loading.fail.skater":    "Impossible de charger les statistiques des joueurs",
  "components.participantstats.loading.fail.goalie":    "Impossible de charger les statistiques de gardiens",
  "components.participantstats.no.stats.skater":        "Il n'y a pas de statistiques de joueurs pour cet horaire",
  "components.participantstats.no.stats.goalie":        "Il n'y a pas de statistiques de gardiens pour cet horaire",
  "components.participantstats.last.5.games":           "5 Derniers Matchs",
  "components.participantstats.game.by.game":           "Match Par Match",
  "components.participantstats.gp":                     "MJ",
  "components.participantstats.g":                      "B",
  "components.participantstats.a":                      "A",
  "components.participantstats.pts":                    "PTS",
  "components.participantstats.w":                      "V",
  "components.participantstats.l":                      "D",
  "components.participantstats.t":                      "N",
  "components.participantstats.gaa":                    "Moy",
  "components.participantstats.sv":                     "SV%",
  "components.myteam.go.to.team.page":                  "Aller à la page de l'équipe",
  "components.myteam.pin":                              "Épingler l'équipe à l'en-tête",
  "components.newslist.loading.fail":                   "Impossible de charger les nouvelles",
  "components.newslist.no.news":                        "Il n'y a pas de nouvelles en ce moment",
  "components.page.not.found":                          "Page non trouvée",
  "components.page.error":                              "Erreur",
  "components.page.loading":                            "Chargement de la page...",
  "components.page.no.page":                            "Désolé, il n'y a pas de page à cette adresse.",
  "components.page.something.happened":                 "Quelque chose s'est produit lors du chargement de cette page. Vous pouvez essayer d'actualiser maintenant ou réessayer ultérieurement.",
  "components.profile.birthdate":                       "Anniversaire",
  "components.profile.hometown":                        "Ville Natale",
  "components.profile.height":                          "Taille",
  "components.profile.weight":                          "Poids",
  "components.profile.shoots":                          "Tires",
  "components.profile.catches":                         "Captures",
  "components.profile.view.full":                       "Voir le profil complet",
  "components.player.loading.fail":                     "Impossible de charger le joueur",
  "components.schedulelist.loading":                    "Chargement des horaires...",
  "components.schedulelist.loading.fail":               "Impossible de charger les horaires",
  "components.schedulelist.no.upcoming":                "Il n'y a pas d'horaires à venir",
  "components.standings.select.schedule":               "Sélectionnez un horaire pour afficher le classement",
  "components.standings.loading":                       "Chargement du classement...",
  "components.standings.loading.fail":                  "Impossible de charger le classement",
  "components.standings.no.standings":                  "Il n'y a pas de classement pour cet horaire",
  "components.teamselector.step.offices":               "Pour commencer, sélectionnez votre association",
  "components.teamselector.step.divisions":             "Sélectionnez votre division",
  "components.teamselector.step.teams":                 "Sélectionnez votre équipe",
  "components.teamselector.division.loading.fail":      "Impossible de charger les divisions",
  "components.teamselector.division.no.divisions":      "Aucune division trouvée dans l'association sélectionnée pour {season}",
  "components.teamselector.team.loading.fail":          "Impossible de charger les équipes",
  "components.teamselector.team.no.teams":              "Aucune équipe trouvée dans la ligue sélectionnée",
  "components.teamselector.associations":               "Impossible de charger les associations",
  "components.leaguefilters.all.leagues":               "Toutes les ligues",

  // ROUTES
  "routes.home.header.upcoming.events":                 "Évènements à venir",
  "routes.home.header.news":                            "Nouvelles",
  "routes.gamelist.header.schedule":                    "Calendrier",
  "routes.office.loading":                              "Chargement de l'organisation...",
  "routes.office.select.schedule":                      "Sélectionnez un horaire ci-dessus pour voir les classements",
  "routes.office.select.schedule.and.stats":            "Sélectionnez un horaire ci-dessus pour afficher les statistiques et classements",
  "routes.office.not.available":                        "Ce l'organisation n'est pas disponible sur ce site.",
  "routes.office.doesnt.exist":                         "Ce l'organisation n'existe pas. Vous voudrez peut-être essayer une autre page.",
  "routes.office.something.went.wrong.with.code":       "Une erreur s'est produite lors du chargement de cette organisation ({status}). Vous devrez peut-être actualiser ou réessayer sous peu.",
  "routes.office.something.went.wrong":                 "Une erreur s'est produite lors du chargement de cette page. Vous devrez peut-être actualiser ou réessayer sous peu.",
  "routes.standings.select.season":                     "Sélectionnez une saison ci-dessus",
  "routes.standings.no.standings":                      "Il n'y a pas de classement {type}",
  "routes.team.loading":                                "Chargement de l'équipe...",
  "routes.team.not.available":                          "Cette équipe n'est pas disponible sur ce site.",
  "routes.team.does.not.exist":                         "Cette équipe n'existe pas. Vous voudrez peut-être essayer une autre page.",
  "routes.team.something.went.wrong.with.code":         "Quelque chose s'est mal passé tout en chargeant cette équipe ({status}).Vous devrez peut-être actualiser ou réessayer sous peu.",
  "routes.team.something.went.wrong":                   "Quelque chose s'est mal passé en chargeant cette page. Vous devrez peut-être actualiser ou réessayer sous peu.",
  "routes.team.from.past.season":                       "Cette page d'équipe est d'une saison précédente",
  "routes.schedule.loading":                            "Chargement de l'horaire...",
  "routes.schedule.not.available":                      "Cet horaire n'est pas disponible sur ce site Web.",
  "routes.schedule.not.exist":                          "Cet horaire n'existe pas. Vous voudrez peut-être essayer une autre page.",
  "routes.schedule.something.went.wrong.with.code":     "Quelque chose s'est mal passé tout en chargeant cet horaire ({status}). Vous devrez peut-être actualiser ou réessayer sous peu.",
  "routes.schedule.something.went.wrong":               "Quelque chose s'est mal passé en chargeant cette page. Vous devrez peut-être actualiser ou réessayer sous peu.",
  "routes.schedule.from.past.season":                   "Cette page d'horaire vient d'une saison précédente",

  // COMMON
  "common.enums.periods.OT.long":                       "Prolongation",
  "common.enums.periods.OT.short":                      "PROL",
  "common.enums.periods.SO.long":                       "Fusillade",
  "common.enums.periods.SO.short":                      "FU",
  "common.enums.periods.1.short":                       "1re",
  "common.enums.periods.2.short":                       "2e",
  "common.enums.periods.3.short":                       "3e",
  "common.enums.periods.1.long":                        "1re Période",
  "common.enums.periods.2.long":                        "2e Période",
  "common.enums.periods.3.long":                        "3e Période",
  "common.enums.goals.shorthanded.abbr":                "But IN",
  "common.enums.goals.powerplay.abbr":                  "But AN",
  "common.enums.goals.emptynet.abbr":                   "But FD",
  "common.enums.positions.F.abbr":                      "A",
  "common.enums.positions.C.abbr":                      "C",
  "common.enums.positions.D.abbr":                      "D",
  "common.enums.genders.Male":                          "Masculin",
  "common.enums.genders.Female":                        "Féminin",
  "common.enums.genders.Integrated":                    "Mixte",
  "common.enums.scheduletypes.Playoffs":                "Séries Éliminatoires",
  "common.enums.scheduletypes.League":                  "Ligue",
  "common.enums.scheduletypes.Placement":               "Classement",
  "common.enums.scheduletypes.Exhibition":              "Exhibition",
  "common.enums.scheduletypes.Tournament":              "Tournoi",
  "common.enums.scheduletypes.Championship":            "Championnat",
  "common.enums.scheduletypes.Cup":                     "Coupe",
  "common.enums.officetypes.League":                    "Ligue",
  "common.enums.officetypes.Tournament":                "Tournoi",
  "common.enums.officetypes.Cup":                       "Coupe",
  "common.enums.officetypes.Association":               "Association",
  "common.enums.officetypes.District":                  "District",
  "common.enums.officetypes.Region":                    "Région",
  "common.enums.officetypes.Branch":                    "Branche",
  "common.enums.winloss.regulation":                    "FINALE",
  "common.enums.winloss.overtime":                      "FINALE/PROL",
  "common.enums.winloss.shootout":                      "FINALE/FU",
  "common.enums.winloss.forfeit":                       "FORFAIT",
  "common.enums.winloss.doubleForfeit":                 "DOUBLE FORFAIT",
  "common.enums.gamestatus.Cancelled":                  "Annulé",
  "common.enums.gamestatus.Postponed":                  "Reporté",
  "common.enums.gamestatus.Conflict":                   "Conflit",
  "common.enums.gamestatus.Active":                     "Active",
  "common.enums.gamestatus.Rescheduled":                "Reporté",
  "common.enums.playerstatus.C":                        "C",
  "common.enums.playerstatus.A":                        "A",
  "common.enums.playerstatus.AP":                       "JA",
  "common.enums.playerstatus.OA":                       "SuC",
  "common.enums.playerstatus.UA":                       "SoC",
  "common.enums.playerstatus.SUS":                      "SUS",
  "common.enums.playerstatus.S":                        "P",
  "common.enums.playerstatus.Rls":                      "L",
  "common.lang.period":                                 "Période",
  "common.lang.scoring":                                "Buts",
  "common.lang.penalties":                              "Punitions",
  "common.lang.unassisted":                             "Sans aide",
  "common.lang.player":                                 "Joueur",
  "common.lang.penalty":                                "Punition",
  "common.lang.skaters":                                "Patineurs",
  "common.lang.goalies":                                "Gardiens",
  "common.lang.goalie":                                 "Gardien",
  "common.lang.standings":                              "Classements",
  "common.lang.schedule":                               "Horaire",
  "common.lang.calendar":                               "Calendrier",
  "common.lang.stats":                                  "Statistiques",
  "common.lang.stats.and.standings":                    "Statistiques et Classements",
  "common.lang.leagues":                                "Ligues",
  "common.lang.league":                                 "Ligue",
  "common.lang.team":                                   "Équipe",
  "common.lang.teams":                                  "Équipes",
  "common.lang.rank":                                   "Rang",
  "common.lang.rank.abbr":                              "RG",
  "common.lang.position":                               "Position",
  "common.lang.position.abbr":                          "Pos",
  "common.lang.jersey":                                 "Chandail",
  "common.lang.back":                                   "Retour",
  "common.lang.go.back":                                "Retourner",
  "common.lang.right":                                  "Droite",
  "common.lang.left":                                   "Gauche",
  "common.lang.tournaments":                            "Tournois",
  "common.lang.type":                                   "Type",
  "common.lang.dates":                                  "Dates",
  "common.lang.date":                                   "Date",
  "common.lang.division":                               "Division",
  "common.lang.association":                            "Association",
  "common.lang.regions":                                "Régions",
  "common.lang.regularseason":                          "Saison Régulière",
  "common.lang.no.options":                             "Aucune option",
  "common.lang.shots":                                  "Tirs",
  "common.lang.all.rights.reserved":                    "Touts droits réservés",
  "common.lang.past.season":                            "Saison passée",
  "common.lang.tbd":                                    "À déterminer",
  "common.lang.show.all.games":                         "Afficher tout les matchs",
  "common.lang.show.all.upcoming.games":                "Afficher tous les matchs à venir",
  "common.lang.game":                                   "Match",
  "common.lang.matchup":                                "Adversaire",
}

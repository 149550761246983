import React, { useContext, useEffect, useState } from 'react';
import { getStorage } from '../../lib';

const store = getStorage('TeamSelector')

export const MyTeamContext = React.createContext([ null, () => {} ])
export const MyTeamProvider = props => {
  const [ state, setState ] = useState(null);

  const setTeam = (teamId, skipSave = false) => {
    setState(teamId);
    if (!teamId) {
      store.removeItem('selection')
    } else if (!skipSave) {
      store.setItem('selection', teamId)
    }
  }

  useEffect(() => {
    store.getItem('selection').then(value => {
      if (!value) return;
      if (typeof value === 'object') {
        setTeam(value?.team?.id);
      } else if (Number.isFinite(value)) {
        setTeam(value, true);
      }
    })
  }, [])

  return <MyTeamContext.Provider value={[ state, setTeam ]} {...props} />
}

export const useMyTeam = () => {
  return useContext(MyTeamContext);
}

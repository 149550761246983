import React, { Fragment, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import moment from 'moment-timezone';

import 'moment/locale/fr'

import { useStorage } from '../lib';

import { LOCALES, messages } from './locales';

const I18nContext = React.createContext([]);

export const getGenericLocale = (locale) => {
  switch (locale) {
    case 'fr-ca':
      return 'fr';
    case 'en-ca':
    default:
      return 'en';
  }
}

export const buildLocale = (locale) => {
  switch (locale?.split(/(-|_)/)[0]) {
    case 'fr':
      return LOCALES.FRENCH;
    case 'en':
    default:
      return LOCALES.ENGLISH;
  }
};

const getDefaultLocale = () => {
  return window.navigator?.language?.toLowerCase()
    || window.navigator?.userLanguage?.toLowerCase()
    || 'en-ca';
}

export const useLocale = () => useContext(I18nContext);

export const I18nProvider = (props) => {
  const state = useStorage('preferences', 'locale', buildLocale(getDefaultLocale()))
  const [ locale, , ready ] = state;
  if (!ready) return null; // wait for preference to finish loading to avoid moment issues

  moment.locale(locale);

  return <I18nContext.Provider value={state}>
    <IntlProvider
      locale={locale}
      defaultLocale={LOCALES.ENGLISH}
      textComponent={Fragment}
      messages={messages[locale]}
      {...props}
    />
  </I18nContext.Provider>
}

export { LOCALES, messages } from './locales';

import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Translate from '@spordle/intl-elements';

import { translateApiProperty } from '@hisports/common';

import { useOffices } from '../../queries/offices';
import { useTeams } from '../../queries/teams';
import { useConfig } from '../ConfigContext';
import { useLocale } from '../../i18n';

import Office from './Steps/Office';
import Division from './Steps/Division';
import Team from './Steps/Team';

const STEP_OFFICES = 1;
const STEP_DIVISIONS = 2;
const STEP_TEAMS = 3;

const titles = {
  [STEP_OFFICES]: <Translate id="components.teamselector.step.offices" />,
  [STEP_DIVISIONS]: <Translate id="components.teamselector.step.divisions" />,
  [STEP_TEAMS]: <Translate id="components.teamselector.step.teams" />,
}

const Breadcrumbs = ({ step, offices, divisions, office, divisionId, setStep }) => {
  const [ locale ] = useLocale();
  const { seasonId, siteType } = useConfig();

  const showOffices = offices?.length > 1 && siteType !== 'League';
  const showDivisions = divisions?.length > 1;
  const showBack = showOffices || showDivisions;

  if (step === STEP_OFFICES || !office) return null;
  if (step === STEP_DIVISIONS && !showOffices) return null;

  let breadcrumbs = office.name;
  if (step === STEP_TEAMS) {
    const division = divisions.find(division => division.id === divisionId);
    breadcrumbs += ` / ${translateApiProperty(division, 'name', locale)} / ${seasonId}`
  }

  const goBack = () => {
    if (step === STEP_TEAMS && showDivisions) return setStep(STEP_DIVISIONS)
    if (showOffices) return setStep(STEP_OFFICES);
  }

  return <div className="w-100 px-4 px-sm-5 d-flex justify-content-start nav-selector-selected">
    <img className="w-auto" src={office.logo && office.logo.url} />
    <div className="pl-3 d-flex flex-column">
      {breadcrumbs}
      {showBack && <div className="nav-selector-back" onClick={goBack}>
        ← <Translate id="common.lang.go.back" />
      </div>}
    </div>
  </div>
}

export default () => {
  const [ isOpen, setOpen ] = useState(false);
  const [ step, setStep ] = useState(STEP_OFFICES);
  const [ office, setOffice ] = useState(null);
  const [ divisionId, setDivisionId ] = useState(null);

  const { siteType, officeId, seasonId } = useConfig();
  const { data: offices, status: officeStatus } = useOffices({ type: { neq: 'League' } }, { enabled: isOpen })
  const { data: teams, status: teamStatus } = useTeams({ officeId: office?.id, seasonId }, { enabled: isOpen && !!office?.id })

  const toggle = () => setOpen(open => !open);

  const selectOffice = office => {
    setOffice(office)
    setStep(STEP_DIVISIONS)
  }

  const selectDivision = divisionId => {
    setDivisionId(divisionId)
    setStep(STEP_TEAMS)
  }

  const selectTeam = team => {
    setOpen(false);
  }

  useEffect(() => {
    if (offices?.length === 1) return selectOffice(offices[0])
    if (siteType !== 'League') return;
    const league = offices?.find(office => office.id === officeId);
    if (league) selectOffice(league)
  }, [ siteType, officeId, offices ])

  let selector = null;
  let categories = [];
  let divisions = [];
  if (teams?.length > 0) {
    categories = teams.map(team => team.category).reduce((categories, category) => {
      const categoryIds = categories.map(category => category.id)
      if (categoryIds.includes(category.id)) return categories;
      categories.push(category);
      return categories;
    }, [])
    divisions = categories.map(category => category.division).reduce((divisions, division) => {
      const divisionIds = divisions.map(division => division.id);
      if (divisionIds.includes(division.id)) return divisions;
      divisions.push(division);
      return divisions;
    }, [])
  }

  if (step === STEP_OFFICES && siteType !== 'League') {
    selector = <Office offices={offices} status={officeStatus} onSelect={selectOffice} />
  } else if (step === STEP_DIVISIONS) {
    selector = <Division divisions={divisions} status={teamStatus} seasonId={seasonId} onSelect={selectDivision} />
  } else if (step === STEP_TEAMS) {
    selector = <Team teams={teams} divisionId={divisionId} categories={categories} status={teamStatus} onSelect={selectTeam} />
  }

  return <Dropdown className="menu-item nav-item" isOpen={isOpen} toggle={toggle} inNavbar>
    <DropdownToggle caret tag="a" nav>
      <Translate id="common.lang.teams" />
    </DropdownToggle>
    <DropdownMenu className="nav-selector-menu dropdown-menu-right">
      <div>
        <div className="w-100 pt-4 px-4 px-sm-5">
          <h2>{titles[step]}</h2>
        </div>
        <Breadcrumbs step={step} offices={offices} divisions={divisions} office={office} divisionId={divisionId} setStep={setStep} />
        <div className="nav-selector-step">
          {selector}
        </div>
      </div>
    </DropdownMenu>
  </Dropdown>
}

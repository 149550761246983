import React from 'react';
import { withRouter, Link } from 'react-router-dom';

const NavLink = ({ location, to, label, ...props }) => {
  const classes = ['menu-item', 'nav-item'];
  const active = location.pathname.startsWith(to);
  if (active) {
    classes.push('current_page_item', 'active');
  }

  return <li className={classes.join(' ')}>
    <Link className="nav-link" to={to} {...props}>{label}</Link>
  </li>
}

export default withRouter(NavLink);

import React from 'react';
import { Facebook, Twitter, Instagram, Youtube } from 'react-feather';
import cn from 'classnames';
import Translate from '@spordle/intl-elements';

import LanguageMenu from './LanguageMenu';
import { useConfig } from './ConfigContext';

const Icons = {
  'twitter': Twitter,
  'facebook': Facebook,
  'instagram': Instagram,
  'youtube': Youtube
}

const SocialIcon = ({ type, url }) => {
  const Icon = Icons[type];
  if (!Icon || !url) return null;
  return <a href={url} target="_blank" rel="noopener noreferrer" className="mx-2">
    <Icon color="white" size={32} strokeWidth={1} />
  </a>
}

export default () => {
  const { appName, socialTwitter, socialFacebook, socialInstagram, socialYouTube } = useConfig();

  const url = 'https://spordle.com';
  const logo = 'play-logo.png';
  const classes = cn('row', 'text-light', 'play')

  return <div className="container-fluid bg-brand">
    <footer className={classes}>
      <div className="col-12 d-flex align-items-center p-4">
        <div className="pl-4 pr-5">
          <a href={url}>
            <img src={logo} alt={appName} />
          </a>
        </div>
        <small className="d-none d-sm-block">{new Date().getFullYear()} © <Translate id="common.lang.all.rights.reserved" /></small>
        <div className="footer-social">
          <SocialIcon type="youtube" url={socialYouTube} />
          <SocialIcon type="instagram" url={socialInstagram} />
          <SocialIcon type="facebook" url={socialFacebook} />
          <SocialIcon type="twitter" url={socialTwitter} />
        </div>
        <LanguageMenu />
      </div>
    </footer>
  </div>
}

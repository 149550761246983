/* eslint-disable key-spacing */
export default {
  // COMPONENTS
  "components.boxscore.loading":                        "Loading box score...",
  "components.boxscore.loading.fail":                   "Unable to load this game",
  "components.boxscore.game.title":                     "Game {number}",
  "components.boxscore.table.header.final":             "Final",
  "components.boxscore.table.header.1st":               "1ST",
  "components.boxscore.table.header.2nd":               "2ND",
  "components.boxscore.table.header.3rd":               "3RD",
  "components.boxscore.table.header.overtime":          "OT",
  "components.boxscore.table.header.shootout":          "SO",
  "components.boxscore.table.header.total":             "T",
  "components.boxscore.table.header.final.title":       "Final",
  "components.boxscore.table.header.1st.title":         "First Period",
  "components.boxscore.table.header.2nd.title":         "Second Period",
  "components.boxscore.table.header.3rd.title":         "Third Period",
  "components.boxscore.table.header.overtime.title":    "Overtime",
  "components.boxscore.table.header.shootout.title":    "Shootout",
  "components.boxscore.table.header.total.title":       "Total",
  "components.boxscore.view.full.game.report":          "View full game report",
  "components.boxscore.scoring.no.goals":               "There are no goals for this game",
  "components.boxscore.shots.table.header":             "Shots on Net",
  "components.eventslist.load.fail":                    "Unable to load upcoming events at this time",
  "components.eventslist.no.events":                    "There are no upcoming events at this time",
  "components.filters.category":                        "Category",
  "components.filters.division":                        "Division",
  "components.filters.gender":                          "Gender Identity",
  "components.filters.group":                           "All Groups",
  "components.filters.office":                          "League / Association",
  "components.filters.schedule":                        "Schedule",
  "components.filters.schedule.type":                   "Season Type",
  "components.filters.season":                          "Season",
  "components.gamelist.loading":                        "Loading schedule...",
  "components.gamelist.load.fail":                      "Unable to load games",
  "components.gamelist.no.games.today":                 "There are no games scheduled today",
  "components.gamelist.no.games.that.day":              "There are no games scheduled on {day}",
  "components.gamelist.no.games.that.day.past":         "There were no games scheduled on {day}",
  "components.gamelist.no.upcoming.games":              "There are no upcoming games",
  "components.gamelist.tap.to.read.more":               "tap to read more",
  "components.leagueselector.no.leagues.found":         "No leagues found",
  "components.leagueselector.unable.to.load":           "Unable to load leagues",
  "components.participantstats.select.schedule":        "Select a schedule to show stats",
  "components.participantstats.loading":                "Loading stats...",
  "components.participantstats.loading.fail.skater":    "Unable to load skater stats",
  "components.participantstats.loading.fail.goalie":    "Unable to load goalie stats",
  "components.participantstats.no.stats.skater":        "There are no skater stats for this schedule",
  "components.participantstats.no.stats.goalie":        "There are no goalie stats for this schedule",
  "components.participantstats.last.5.games":           "Last 5 Games",
  "components.participantstats.game.by.game":           "Game by Game",
  "components.participantstats.gp":                     "GP",
  "components.participantstats.g":                      "G",
  "components.participantstats.a":                      "A",
  "components.participantstats.pts":                    "PTS",
  "components.participantstats.w":                      "W",
  "components.participantstats.l":                      "L",
  "components.participantstats.t":                      "T",
  "components.participantstats.gaa":                    "GAA",
  "components.participantstats.sv":                     "SV%",
  "components.myteam.go.to.team.page":                  "Go to team page",
  "components.myteam.pin":                              "Pin team to header",
  "components.newslist.loading.fail":                   "Unable to load news",
  "components.newslist.no.news":                        "There is no news at this time",
  "components.page.not.found":                          "Page not found",
  "components.page.error":                              "Error",
  "components.page.loading":                            "Loading page...",
  "components.page.no.page":                            "Sorry, there is no page at this address.",
  "components.page.something.happened":                 "Something happened while loading this page. You can try refreshing now or try again at a later time.",
  "components.profile.birthdate":                       "Birthdate",
  "components.profile.hometown":                        "Hometown",
  "components.profile.height":                          "Height",
  "components.profile.weight":                          "Weight",
  "components.profile.shoots":                          "Shoots",
  "components.profile.catches":                         "Catches",
  "components.profile.view.full":                       "View full profile",
  "components.player.loading.fail":                     "Unable to load player",
  "components.schedulelist.loading":                    "Loading schedules...",
  "components.schedulelist.loading.fail":               "Unable to load schedules",
  "components.schedulelist.no.upcoming":                "There are no upcoming schedules",
  "components.standings.select.schedule":               "Select a schedule to show standings",
  "components.standings.loading":                       "Loading standings...",
  "components.standings.loading.fail":                  "Failed to load standings",
  "components.standings.no.standings":                  "There are no standings for this schedule",
  "components.teamselector.step.offices":               "To begin, select your association",
  "components.teamselector.step.divisions":             "Select your division",
  "components.teamselector.step.teams":                 "Select your team",
  "components.teamselector.division.loading.fail":      "Unable to load divisions",
  "components.teamselector.division.no.divisions":      "No divisions found in the selected association during {season}",
  "components.teamselector.team.loading.fail":          "Unable to load teams",
  "components.teamselector.team.no.teams":              "No teams found in the selected league",
  "components.teamselector.associations":               "Unable to load associations",
  "components.leaguefilters.all.leagues":               "All Leagues",

  // ROUTES
  "routes.home.header.upcoming.events":                 "Upcoming Events",
  "routes.home.header.news":                            "News",
  "routes.gamelist.header.schedule":                    "Schedule",
  "routes.office.loading":                              "Loading office...",
  "routes.office.select.schedule":                      "Select a schedule above to view standings",
  "routes.office.select.schedule.and.stats":            "Select a schedule above to view stats and standings",
  "routes.office.not.available":                        "This office is not available on this website.",
  "routes.office.doesnt.exist":                         "This office doesn't exist. You may want to try another page.",
  "routes.office.something.went.wrong.with.code":       "Something went wrong while loading this office ({status}). You may need to refresh or try again shortly.",
  "routes.office.something.went.wrong":                 "Something went wrong while loading this page. You may need to refresh or try again shortly.",
  "routes.standings.select.season":                     "Select a season above",
  "routes.standings.no.standings":                      "There are no {type} standings",
  "routes.team.loading":                                "Loading team...",
  "routes.team.not.available":                          "This team is not available on this website.",
  "routes.team.does.not.exist":                         "This team doesn't exist. You may want to try another page.",
  "routes.team.something.went.wrong.with.code":         "Something went wrong while loading this team ({status}). You may need to refresh or try again shortly.",
  "routes.team.something.went.wrong":                   "Something went wrong while loading this page. You may need to refresh or try again shortly.",
  "routes.team.from.past.season":                       "This team page is from a past season",
  "routes.schedule.loading":                            "Loading schedule...",
  "routes.schedule.not.available":                      "This schedule is not available on this website.",
  "routes.schedule.not.exist":                          "This schedule doesn't exist. You may want to try another page.",
  "routes.schedule.something.went.wrong.with.code":     "Something went wrong while loading this schedule ({status}). You may need to refresh or try again shortl.",
  "routes.schedule.something.went.wrong":               "Something went wrong while loading this page. You may need to refresh or try again shortly.",
  "routes.schedule.from.past.season":                   "This schedule page is from a past season",

  // COMMON
  "common.enums.periods.OT.long":                       "Overtime",
  "common.enums.periods.OT.short":                      "OT",
  "common.enums.periods.SO.long":                       "Shootout",
  "common.enums.periods.SO.short":                      "SO",
  "common.enums.periods.1.short":                       "1st",
  "common.enums.periods.2.short":                       "2nd",
  "common.enums.periods.3.short":                       "3rd",
  "common.enums.periods.1.long":                        "1st Period",
  "common.enums.periods.2.long":                        "2nd Period",
  "common.enums.periods.3.long":                        "3rd Period",
  "common.enums.goals.shorthanded.abbr":                "SHG",
  "common.enums.goals.powerplay.abbr":                  "PPG",
  "common.enums.goals.emptynet.abbr":                   "ENG",
  "common.enums.positions.F.abbr":                      "F",
  "common.enums.positions.C.abbr":                      "C",
  "common.enums.positions.D.abbr":                      "D",
  "common.enums.genders.Male":                          "Male",
  "common.enums.genders.Female":                        "Female",
  "common.enums.genders.Integrated":                    "Integrated",
  "common.enums.scheduletypes.Playoffs":                "Playoffs",
  "common.enums.scheduletypes.League":                  "League",
  "common.enums.scheduletypes.Placement":               "Placement",
  "common.enums.scheduletypes.Exhibition":              "Exhibiton",
  "common.enums.scheduletypes.Tournament":              "Tournament",
  "common.enums.scheduletypes.Championship":            "Championship",
  "common.enums.scheduletypes.Cup":                     "Cup",
  "common.enums.officetypes.League":                    "League",
  "common.enums.officetypes.Tournament":                "Tournament",
  "common.enums.officetypes.Cup":                       "Cup",
  "common.enums.officetypes.Association":               "Association",
  "common.enums.officetypes.District":                  "District",
  "common.enums.officetypes.Region":                    "Region",
  "common.enums.officetypes.Branch":                    "Branch",
  "common.enums.winloss.regulation":                    "FINAL",
  "common.enums.winloss.overtime":                      "FINAL/OT",
  "common.enums.winloss.shootout":                      "FINAL/SO",
  "common.enums.winloss.forfeit":                       "FORFEITED",
  "common.enums.winloss.doubleForfeit":                 "DOUBLE FORFEIT",
  "common.enums.gamestatus.Cancelled":                  "Cancelled",
  "common.enums.gamestatus.Postponed":                  "Postponed",
  "common.enums.gamestatus.Conflict":                   "Conflict",
  "common.enums.gamestatus.Active":                     "Active",
  "common.enums.gamestatus.Rescheduled":                "Rescheduled",
  "common.enums.playerstatus.C":                        "C",
  "common.enums.playerstatus.A":                        "A",
  "common.enums.playerstatus.AP":                       "AP",
  "common.enums.playerstatus.OA":                       "OA",
  "common.enums.playerstatus.UA":                       "UA",
  "common.enums.playerstatus.SUS":                      "SUS",
  "common.enums.playerstatus.S":                        "S",
  "common.enums.playerstatus.Rls":                      "Rls",
  "common.lang.period":                                 "Period",
  "common.lang.scoring":                                "Scoring",
  "common.lang.penalties":                              "Penalties",
  "common.lang.unassisted":                             "Unassisted",
  "common.lang.player":                                 "Player",
  "common.lang.penalty":                                "Penalty",
  "common.lang.skaters":                                "Skaters",
  "common.lang.goalies":                                "Goalies",
  "common.lang.goalie":                                 "Goalie",
  "common.lang.standings":                              "Standings",
  "common.lang.schedule":                               "Schedule",
  "common.lang.calendar":                               "Schedule",
  "common.lang.stats":                                  "Stats",
  "common.lang.stats.and.standings":                    "Stats and Standings",
  "common.lang.leagues":                                "Leagues",
  "common.lang.league":                                 "League",
  "common.lang.team":                                   "Team",
  "common.lang.teams":                                  "Teams",
  "common.lang.rank":                                   "Rank",
  "common.lang.rank.abbr":                              "RK",
  "common.lang.position":                               "Position",
  "common.lang.position.abbr":                          "Pos",
  "common.lang.jersey":                                 "Jersey",
  "common.lang.back":                                   "Back",
  "common.lang.go.back":                                "Go back",
  "common.lang.right":                                  "Right",
  "common.lang.left":                                   "Left",
  "common.lang.tournaments":                            "Tournaments",
  "common.lang.type":                                   "Type",
  "common.lang.dates":                                  "Dates",
  "common.lang.date":                                   "Date",
  "common.lang.division":                               "Division",
  "common.lang.association":                            "Association",
  "common.lang.regions":                                "Regions",
  "common.lang.regularseason":                          "Regular Season",
  "common.lang.no.options":                             "No options",
  "common.lang.shots":                                  "Shots",
  "common.lang.all.rights.reserved":                    "All rights reserved",
  "common.lang.past.season":                            "Past season",
  "common.lang.tbd":                                    "TBD",
  "common.lang.show.all.games":                         "Show all games",
  "common.lang.show.all.upcoming.games":                "Show all upcoming games",
  "common.lang.game":                                   "Game",
  "common.lang.matchup":                                "Matchup",
}
